import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PersonalDataComponent } from '../personal-data/personal-data.component';
import { MyTicketsComponent } from '../my-tickets/my-tickets.component';
import { OrderHistoryComponent } from '../order-history/order-history.component';


const routes: Routes = [
    {
        path: '',
        redirectTo: 'personal-data',
        pathMatch: 'full',
        title: 'My Account | Personal Data',
    },
    {
        path: 'personal-data',
        component: PersonalDataComponent,
        loadChildren: ()=> import('../personal-data/personal-data.module').then(m => m.PersonalDataModule)
    },
    {
        path: 'my-tickets',
        component: MyTicketsComponent,
        loadChildren: ()=> import('../my-tickets/my-tickets.module').then(m => m.MyTicketsModule)
    },
    {
        path: 'order-history',
        component: OrderHistoryComponent,
        loadChildren: ()=> import('../order-history/order-history.module').then(m => m.OrderHistoryModule)
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class MyAccountRoutingModule { }